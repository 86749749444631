<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="标题" prop="title">
                <a-input v-model="queryParam.title" placeholder="请输入标题" allow-clear />
              </a-form-item>
            </a-col>
            <!-- <a-col :md="8" :sm="24">
              <a-form-item label="类型">
                <a-select placeholder="请选择数据类型" v-model="queryParam.type" style="width: 100%" allow-clear>
                  <a-select-option value="0">
                    引导页
                  </a-select-option>
                  <a-select-option value="1">
                    启动页
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col> -->
            <!-- <a-col :md="8" :sm="24">
              <a-form-item label="引导页启动页类型">
                <a-select placeholder="请选择引导页启动页类型" v-model="queryParam.guideType" style="width: 100%" allow-clear>
                  <a-select-option value="0">
                    图片
                  </a-select-option>
                  <a-select-option value="1">
                    视频
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col> -->
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <!-- <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a> -->
              </span>
            </a-col>
          </a-row>
        </a-form>

      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['trip:guide/page:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['trip:guide/page:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['trip:guide/page:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />

      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered">
        <!---图片显示--->
        <span slot="pic" slot-scope="text, record">
          <img style="width:50px;heigth:50px" :src="record.imgUrl" />
        </span>
        <span slot="video" slot-scope="text, record">
          <video :src="record.vedioUrl" controls width="50%" height="50%">
          </video>
        </span>
        <span slot="operation" slot-scope="text, record">
          <!-- <a-divider type="vertical" v-hasPermi="['trip:guide/page:edit']" /> -->
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['trip:guide/page:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['trip:guide/page:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['trip:guide/page:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageGuidePage, listGuidePage, delGuidePage } from '@/api/valve/guidePage'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import { FileUpload } from '@/components/FileUpload/index'

export default {
  name: 'GuidePage',
  components: {
    CreateForm, FileUpload
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        title: null,
        type: null,
        guideType: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [

        {
          title: '标题',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '类型',
          dataIndex: 'typeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '引导页启动页类型',
          dataIndex: 'guideTypeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '描述',
          dataIndex: 'description',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '图片地址',
          dataIndex: 'imgUrl',
          ellipsis: true,
          scopedSlots: { customRender: 'pic' },
          align: 'center'
        },
        {
          title: '视频地址',
          dataIndex: 'vedioUrl',
          ellipsis: true,
          scopedSlots: { customRender: 'video' },
          align: 'center'
        },
        {
          title: '轮播时间(秒)',
          dataIndex: 'time',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询引导页和启动页列表 */
    getList() {
      this.loading = true
      pageGuidePage(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.list.forEach(element => {
          if (element.guideType == 0) {
            element.guideTypeName = '图片'
          } else if (element.guideType == 1) {
            element.guideTypeName = '视频'
          }
          if (element.type == 0) {
            element.typeName = '引导页'
          } else if (element.type == 1) {
            element.typeName = '启动页'
          }
        })
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        title: null,
        type: null,
        guideType: null,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delGuidePage(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() { }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('trip/guide/page/export', {
            ...that.queryParam
          }, `引导页和启动页_${new Date().getTime()}.xlsx`)
        },
        onCancel() { }
      })
    }
  }
}
</script>
